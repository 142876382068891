.col-login {
    background-color: #fefefe;
    min-height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.login-form {
    margin: 0 20px;
    width: 100%;
}

.login-form-button {
    font-size: 16px;
    line-height: 16px;
    height: 40px;
}

.login-form .ant-form-item {
    margin-bottom: 16px;
}

@media (min-width: 370px) {
    .login-form {
        width: 350px;
    } 
}