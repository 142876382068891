:root {
    --main-color: #078C8C;
    --accent-color: #58d7c5;
    --light-color: #E7F6F8;
    --dark-color: #2C343C;
    --light-color-2: #CCEBEE;
    --secondary-color: #93DCAC;
    --antd-wave-shadow-color: var(--accent-color);
}

/* ::selection { background: var(--main-color); } */

.ant-layout {
    /* background: rgba(147, 220, 172, 0.25); */
    background: #E7F6F8;
}

.container-sider {
    min-height: 100vh;
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0,21,41,.35);
    background: var(--main-color);
    overflow-x: auto;
    position: fixed;
    left: 0;
}

.menu-sider {
    padding: 16px 0;
    background: var(--main-color);
    margin-bottom: 5px;
}

.ant-layout-sider-children .ant-select-selection {
    border: 1px solid rgb(208, 208, 208);
    background: transparent;
    color: rgb(208, 208, 208);
}

.ant-layout-sider-children .ant-select-selection .ant-select-arrow {
    color: rgb(208, 208, 208);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: var(--main-color);
    filter: contrast(150%);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: rgba(204, 235, 238, 0.3);
}

.logo {
    position: relative;
    padding: 10px 0;
    overflow: hidden;
    box-shadow: inset 0 10px 10px -10px black;
    background: var(--main-color);
    transition: all .3s;
    z-index: 900;
    text-align: center;
    margin-bottom: 10px;
}

.logo img {
    display: inline-block;
    height: auto;
    vertical-align: middle;
    width: 70%;
}

.logo h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #eaeaea;
    font-weight: 600;
    font-size: 18px;
    font-family: Avenir,'Helvetica Neue',Arial,Helvetica,sans-serif;
    vertical-align: middle;
}

.colibrisBlancLogo {
    overflow: hidden;
    padding-top: 20px;
    position: absolute;
    bottom: 20px;
    text-align: center;
}

.colibrisBlancLogo img {
    
    width: 70%;
    vertical-align: middle;
}

.content {
    margin: 88px 24px 24px 280px;
    /* max-height: calc(100vh - 64px - 71px - 50px);
    overflow: auto; */
}

.footer {
    text-align: center;
    margin-left: 256px;
    /* background: rgba(255, 255, 255, 0.3); */
    background: transparent;
    border-top: 1px solid #fff;
    padding: 14px 12px 12px;
}

.footer p { margin-bottom: 0; }
.footer a { font-weight: 600; }

.ant-btn:not(.ant-btn-icon-only) { border-radius: 50px; }

.ant-btn-primary {
    color: #fff;
    border-color: var(--main-color);
    background-color: var(--main-color);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #fff;
    color: var(--main-color);
    border-color: var(--main-color);
}

.ant-btn-link, .ant-btn-link:hover,
.ant-pagination-prev:not(.ant-pagination-disabled), .ant-pagination-next:not(.ant-pagination-disabled), .ant-pagination-jump-prev:not(.ant-pagination-disabled), .ant-pagination-jump-next:not(.ant-pagination-disabled), .ant-pagination-item:not(.ant-pagination-disabled),
.ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-prev:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-prev:not(.ant-pagination-disabled) a, .ant-pagination-next:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-next:not(.ant-pagination-disabled) a, .ant-pagination-jump-prev:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-jump-prev:not(.ant-pagination-disabled) a, .ant-pagination-jump-next:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-jump-next:not(.ant-pagination-disabled) a, .ant-pagination-item:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-item:not(.ant-pagination-disabled) a
{
    border: none;
}

.ant-pagination-item { font-size: 16px; }

.ant-pagination-item-active {
    font-weight: 600;
    font-size: 18px;
}

.ant-pagination-options-size-changer.ant-select .ant-select-selection,
.ant-pagination-options-size-changer.ant-select.ant-select-focused:not(.ant-select-disabled) .ant-select-selection,
.ant-pagination-options-size-changer.ant-select.ant-select-open:not(.ant-select-disabled) .ant-select-selection {
    border: none;
    box-shadow: none;
    background: transparent;
}

.ant-btn-link, .footer a,
.ant-table-tbody > tr > td .anticon:not(.anticon-delete),
.ant-typography a {
    color: var(--dark-color);
}

.ant-btn-link:hover,
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):not(.ant-radio-button-wrapper-disabled):hover > span,
.ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover,
.ant-tabs-nav .ant-tabs-tab-active,
.ant-pagination-item:hover a, .ant-pagination-item:focus a, .ant-pagination-item-active a , .ant-pagination-item-active:hover a,
.ant-typography a:focus, .ant-typography a:hover,
.ant-breadcrumb-link a:hover,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: var(--main-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--main-color);
    background-color: var(--main-color);
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input:hover,
.ant-select:hover .ant-select-selection,
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox, .ant-checkbox-checked::after {
    border-color: var(--main-color);
}

.ant-select-selection--single .ant-select-selection__rendered { margin-right: 32px; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: var(--main-color);
    border-color: var(--main-color);
    box-shadow: -1px 0 0 0 var(--main-color);
}

.ant-radio-group:not(.ant-radio-group-solid) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-btn:not(.ant-btn-danger):not(.ant-btn-disabled):hover, .ant-btn:not(.ant-btn-danger):not(.ant-btn-disabled):focus,
.ant-pagination-item-active, .ant-pagination-item:hover, .ant-pagination-item:focus,
.ant-radio-group:not(.ant-radio-group-solid) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-group:not(.ant-radio-group-solid) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: var(--main-color);
    color: var(--main-color);
}

.ant-radio-button-wrapper > span { transition: color 0.3s; }

.ant-tabs-ink-bar,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before { background-color: var(--main-color) !important; }

.ant-tabs-nav .ant-tabs-tab { text-transform: uppercase; }

.ant-tabs-nav .ant-tabs-tab-active { font-weight: 600; }

h1.ant-typography {
    font-weight: 400;
    color: var(--main-color);
    text-transform: uppercase;
}

.ant-table-thead > tr > th {
    background: #fff;
    color: var(--main-color);
    font-weight: 600;
    text-transform: uppercase;
    transition: color 0.3s;
}

.ant-table-thead > tr > th:hover {
    background: #fff;
    color: var(--dark-color);
}

.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before,.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before { background: transparent; }

.ant-page-header.has-breadcrumb .ant-page-header-heading-title, h2.ant-typography {
    color: var(--dark-color);
}

.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > .ant-btn,
.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > a,
.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > div > .ant-btn,
.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > div > a {
    margin: 0 5px !important;
}

.ant-descriptions-item > span { display: unset; }

.ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    font-size: 0.8;
}

.ant-descriptions-item-content {
    font-size: 1.1em;
}

@media only screen and (max-width: 992px) {
    .content {
        margin-left: 100px;
    }
    .footer {
        margin-left: 0;
    }
    .ant-descriptions-row {
        display: flex;
        flex-direction: column;
    } 
}

@media only screen and (max-width: 576px) {
    .content {
        margin-left: 24px;
    }
    footer {
        margin-left: 0;
    }
}

@media only screen and (max-height: 800px) {
    .container-sider {
        height: 100%;
    }

    .colibrisBlancLogo {
        display: none;
    }
}